import React, { FC, useEffect } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import bigDecimal from 'js-big-decimal';

import { capitalize, showProgress } from '../../../utils/helpers';
import { CalculateMarketBuyData } from "../Calculation/CalculateBuyValues";
import { CalculateMarketSellData } from "../Calculation/CalculateSellValues";
import { MarketsTabsType } from "../../../types/enums";

interface Props {
  data: any,
  tabType: string,
  currentSymbol: string,
  counterEarning: boolean,
  marketPrice?: number,
  onResultsChange?: ({id, firstOrderId, value, profitValue, filledQuantity}: any) => void;
}

export const MarketsTableSecondSubRow: FC<Props> = ({
                                                      data,
                                                      tabType,
                                                      currentSymbol,
                                                      counterEarning,
                                                      marketPrice = 0,
                                                      onResultsChange
                                                    }) => {
  const currentSymbolValue = data.symbol.counterCurrency;
  const marketPriceDecimal = new bigDecimal(marketPrice);
  const dataBuyCreationPrice = bigDecimal.stripTrailingZero(bigDecimal.round(data.firstFilledPrice, data.counterRound));

  const buyResults = CalculateMarketBuyData([data], counterEarning, marketPriceDecimal);
  const sellResults = CalculateMarketSellData([data], counterEarning, marketPriceDecimal);

  const profitValue = tabType === MarketsTabsType.sell ? Number(sellResults.profitQuantity.getValue()) : Number(buyResults.profitQuantity.getValue());
  const profitPercentValue = tabType === MarketsTabsType.sell ? sellResults.profitPercentSecond : buyResults.profitPercentSecond;

  const averageQuantity = tabType === MarketsTabsType.sell ? sellResults.averageQuantitySecond : buyResults.averageQuantitySecond;
  const averageQuantityRemain = averageQuantity > 0 ? 100 - averageQuantity : 0;

  const value = tabType === MarketsTabsType.sell ?
    Number(sellResults.valueSecond.getValue()) ? Number(sellResults.valueSecond.getValue()) : 0 :
    Number(buyResults.valueSecond.getValue()) ? Number(buyResults.valueSecond.getValue()) : 0

  const id = data.blockId
  const filledQuantity = Number(data.firstFilledQuantity)
  const firstOrderId = Number(data.firstOrderId)


  useEffect(() => {

    if (onResultsChange) {
      onResultsChange({id, firstOrderId, value, profitValue, filledQuantity});
    }
  }, [value, profitValue, filledQuantity]);

  return (
    <Row>
      <Col>
        <div className='markets-table__row-main--collapsed ms-2'></div>
      </Col>
      <Col xs={9}>
        <Row className='markets-table__row subrow mt-1'>
          <Col></Col>
          <Col>
            <p style={{color: '#5b6aff'}}>{dataBuyCreationPrice}</p>

          </Col>
          <Col>{data.connectorName}</Col>
          <Col>{Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN).getValue())} {currentSymbolValue}</Col>
          {tabType === 'buy'
            ? <Col
              xs={4} md
              className={`${profitValue > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profitValue > 0 ? "+" + profitValue : profitValue} 
                ${counterEarning ?
              data.symbol.counterCurrency :
              //counterEarning = false
              Number(new bigDecimal(marketPrice).getValue()) === 0 ? data.symbol.counterCurrency :
                Number(
                  (Number(data.firstFilledUnrealizedQuantity.getValue()) > 0
                    ? data.firstFilledUnrealizedQuantity
                    : data.firstFilledQuantity)
                    .multiply(new bigDecimal(marketPrice))
                    .round(4, bigDecimal.RoundingModes.DOWN)
                    .subtract(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN))
                    .getValue()
                ) < Number(new bigDecimal(data.baseQuantityStep).multiply(new bigDecimal(marketPrice)).getValue()) ?
                  data.symbol.counterCurrency :
                  data.symbol.baseCurrency}`}
            </Col>
            : <Col
              xs={4} md
              className={`${profitValue > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profitValue > 0 ? "+" + profitValue : profitValue}
                ${counterEarning ?
              profitValue > 0 ? data.symbol.counterCurrency : data.symbol.baseCurrency :
              Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                .round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                .subtract(
                  (Number(data.firstFilledUnrealizedQuantity) > 0
                    ? new bigDecimal(data.firstFilledUnrealizedQuantity)
                    : new bigDecimal(data.firstFilledQuantity))
                    .multiply(new bigDecimal(marketPrice)).round(4, bigDecimal.RoundingModes.DOWN))
                .getValue()) >= 0 &&
              Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                .round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                .subtract(
                  (Number(data.firstFilledUnrealizedQuantity) > 0
                    ? new bigDecimal(data.firstFilledUnrealizedQuantity)
                    : new bigDecimal(data.firstFilledQuantity))
                    .multiply(new bigDecimal(marketPrice)).round(4, bigDecimal.RoundingModes.DOWN))
                .getValue()) < Number(new bigDecimal(data.baseQuantityStep).multiply(new bigDecimal(marketPrice)).round(4, bigDecimal.RoundingModes.DOWN).getValue()) ?
                data.symbol.counterCurrency : data.symbol.baseCurrency}`}
            </Col>
          }
          {Number(profitPercentValue) > 0
            ? <Col xs={4} md className='text-success my-1 my-md-0'>{`+${Number(profitPercentValue)}%`}</Col>
            : <Col xs={4} md className='text-danger my-1 my-md-0'>{`${Number(profitPercentValue)}%`}</Col>
          }
          <Col
            className={tabType === MarketsTabsType.buy ? 'text-success' : 'text-danger'}>{capitalize(data.section.toLowerCase())}</Col>
          <Col>
            <ProgressBar data-bs-theme='dark'>
              <ProgressBar variant="success" now={averageQuantity} label={showProgress(averageQuantity)} key={1}/>
              <ProgressBar variant="danger" now={averageQuantityRemain} label={showProgress(averageQuantityRemain)}
                           key={2}/>
            </ProgressBar>
          </Col>
          <Col>
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_541_19518)">
                <path
                  d="M9.66928 3.60107H0.888835C0.712024 3.60107 0.542454 3.5277 0.41743 3.39709C0.292406 3.26649 0.222168 3.08935 0.222168 2.90464C0.222168 2.71994 0.292406 2.5428 0.41743 2.41219C0.542454 2.28159 0.712024 2.20822 0.888835 2.20822H9.66928C9.8073 1.80086 10.0628 1.4482 10.4006 1.19878C10.7384 0.949357 11.1418 0.81543 11.5555 0.81543C11.9692 0.81543 12.3726 0.949357 12.7104 1.19878C13.0482 1.4482 13.3037 1.80086 13.4417 2.20822H15.1111C15.2879 2.20822 15.4574 2.28159 15.5825 2.41219C15.7075 2.5428 15.7777 2.71994 15.7777 2.90464C15.7777 3.08935 15.7075 3.26649 15.5825 3.39709C15.4574 3.5277 15.2879 3.60107 15.1111 3.60107H13.4417C13.3037 4.00843 13.0482 4.36109 12.7104 4.61051C12.3726 4.85993 11.9692 4.99386 11.5555 4.99386C11.1418 4.99386 10.7384 4.85993 10.4006 4.61051C10.0628 4.36109 9.8073 4.00843 9.66928 3.60107ZM15.1111 11.0296C15.2879 11.0296 15.4574 10.9563 15.5825 10.8257C15.7075 10.6951 15.7777 10.5179 15.7777 10.3332C15.7777 10.1485 15.7075 9.97137 15.5825 9.84077C15.4574 9.71016 15.2879 9.63679 15.1111 9.63679H6.33061C6.19259 9.22943 5.93711 8.87677 5.59932 8.62735C5.26153 8.37793 4.85804 8.244 4.44439 8.244C4.03074 8.244 3.62725 8.37793 3.28946 8.62735C2.95167 8.87677 2.69619 9.22943 2.55817 9.63679H0.888835C0.712024 9.63679 0.542454 9.71016 0.41743 9.84077C0.292406 9.97137 0.222168 10.1485 0.222168 10.3332C0.222168 10.5179 0.292406 10.6951 0.41743 10.8257C0.542454 10.9563 0.712024 11.0296 0.888835 11.0296H2.55817C2.69578 11.4374 2.95111 11.7906 3.28895 12.0403C3.62679 12.2901 4.03049 12.4243 4.44439 12.4243C4.85829 12.4243 5.26199 12.2901 5.59983 12.0403C5.93767 11.7906 6.193 11.4374 6.33061 11.0296H15.1111Z"
                  fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_541_19518">
                  <rect width="16" height="13" fill="white" transform="translate(0 0.119141)"/>
                </clipPath>
              </defs>
            </svg>
          </Col>
        </Row>
      </Col>
      <Col xs={2}></Col>
    </Row>
  );
};

export default MarketsTableSecondSubRow;