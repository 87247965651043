import React, { FC, useEffect, useId, useState } from 'react';
import { Dropdown, DropdownButton, FormControl } from 'react-bootstrap';
import { SymbolType } from '../../types/types';

interface Props {
  data: SymbolType[],
  title: string,
  value?: string,
  classStyle?: string,
  isSymbols?: boolean,
  handler?: (item: string) => void
  disabled?: boolean
}

export const CustomSelect: FC<Props> = ({
                                          data,
                                          title,
                                          value = '',
                                          classStyle,
                                          isSymbols = false,
                                          handler,
                                          disabled = false
                                        }) => {
  const [dropdownItem, setDropdownItem] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownId = useId();
  const customTitle = isSymbols ? dropdownItem : title;

  const handleChange = (item: string) => {
    if (handler) {
      handler(item);
    } else {
      setDropdownItem(() => item);
    }
  };

  useEffect(() => {
    if (isSymbols) {
      setDropdownItem(title)
    }

    if (value !== '') {
      setDropdownItem(value)
    }

  }, [isSymbols, title, value]);

  const normalizeString = (str: string) => str.replace(/_/g, '').toLowerCase();

  const filteredData = data.filter(item =>
    normalizeString(item.name).includes(normalizeString(searchTerm))
  );

  return (
    <DropdownButton
      id={dropdownId}
      variant="dark"
      title={customTitle}
      data-bs-theme='dark'
      className={classStyle}
      disabled={disabled}
    >
      <FormControl
        autoFocus
        className="mx-3 my-2 w-auto"
        placeholder="Search..."
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />
      {filteredData.map((item, index) => (
        <Dropdown.Item
          key={index}
          eventKey={item.id}
          onClick={() => handleChange(item.name)}
          active={isSymbols ? customTitle === item.name : dropdownItem === item.name}
          bsPrefix='dropdown-item'
        >
          {item.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
