import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";

import '../components/Bot/Bot.scss'
import { Header } from '../components/Header';
import { AlertsTable } from "../components/AlertsTable/AlertsTable";
import useTitle from '../hooks/useTitle';
import useAlertSeen from "../hooks/useAlertSeen";
import { AlertsListTypeContent } from "../types/types";
import { client } from "../api/fetchClient";
import SockJS from "sockjs-client";
import Stomp from "stompjs";


export const AlertsPage = () => {
  useTitle('CopyMaster – your assistant in crypto-trading');
  const [isConnected, setIsConnected] = useState(false);
  const [alertsPrice, setAlertsPrice] = useState(0);
  const [alertExecuted, setAlertExecuted] = useState<AlertsListTypeContent>();
  const [currentMarket, setCurrentMarket] = useState('binance');
  const {setAlertsNotSeenList} = useAlertSeen();
  const websocketUrl = '/websocket-url';

  useEffect(() => {

    const socket = new SockJS(websocketUrl);
    const stompClient = Stomp.over(socket);
    stompClient.debug = true;

    const connectAlertsPriceWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/BINANCE/alert-prices`, (message: any) => {
        const marketMessage = JSON.parse(message.body);
        setAlertsPrice(() => marketMessage)
      });
    };

    const connectAlertsExecutedWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/BINANCE/alert-price-executed`, (message: any) => {
        const marketMessage = JSON.parse(message.body);
        setAlertExecuted(marketMessage);
      });
    };

    const handleSocketClose = () => {
      if (isConnected) {
        stompClient.disconnect(() => {
          setIsConnected(false);
          toast.info('Socket connection closed');
        });
      }
    };

    const websocketMarketPrice = () => {
      handleSocketClose();

      stompClient.connect({
        symbol: 'xrp_usdt',
        market: currentMarket,
        counterEarning: true
      }, (frame: any) => {
        setIsConnected(true);
        const userName = frame.headers['user-name'];
        toast.success('Socket connected');

        stompClient.subscribe(`/user/${userName}/web-id`, (message: any) => {
          try {
            console.log('Market Price Message:', message.body);
            connectAlertsPriceWebsocket(stompClient, message.body);
            connectAlertsExecutedWebsocket(stompClient, message.body);
          } catch (error) {
            console.error('Error processing market price message:', error);
          }
        });

        stompClient.send('/app/init-web-id', {}, JSON.stringify({
          userName,
          market: currentMarket,
          symbolName: 'xrp_usdt',
          counterEarning: true
        }));
      }, (message: any) => {
        if (message.startsWith('Whoops! Lost connection to')) {
          toast.error('Socket lost. Reload this page');
        } else {
          toast.error(`Socket lost. Error: ${message}`);
          setIsConnected(false);
        }
      });
    };

    if (!isConnected) {
      websocketMarketPrice();
    }
    return () => {
      handleSocketClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [currentMarket]);


  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const getAlertsCount = async () => {
      try {
        const loadedData = await client.get<number[]>('/api/alert/not-seen-id-list?market=BINANCE');
        setAlertsNotSeenList(loadedData);
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    getAlertsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header/>

      <main className='position-relative bot bot-page'
            style={{maxWidth: '80rem', margin: '0 auto', padding: '0.5rem 0.5rem 4.5rem 0.5rem'}}>
        <section className='mt-5'>
          <Stack direction='vertical' className='d-flex justify-content-center w-100'>
            <Row className='mt-5'>
              <Col className='mt-3'>
                <h1 className='roboto-bold'>Alerts</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <AlertsTable alertsPrice={alertsPrice} currentMarket={currentMarket} alertExecuted={alertExecuted}/>
              </Col>
            </Row>
          </Stack>
        </section>
      </main>
    </>
  );
};

export default AlertsPage;