import React, { FC, useEffect } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import bigDecimal from 'js-big-decimal';

import { capitalize, showProgress } from '../../../utils/helpers';
import { CalculateMarketBuyData } from "../Calculation/CalculateBuyValues";
import { CalculateMarketSellData } from "../Calculation/CalculateSellValues";
import { MarketsTabsType } from "../../../types/enums";
import { getBackArrowIcon } from "../../../services/icons";

interface Props {
  data: any,
  tabType: string,
  currentSymbol: string,
  counterEarning: boolean,
  marketPrice?: number,
  onResultsChange?: ({id, value, profit, filledQuantity}: any) => void;
}

export const MarketsTableThirdSubRow: FC<Props> = ({
                                                     data,
                                                     tabType,
                                                     currentSymbol,
                                                     counterEarning,
                                                     marketPrice = 0,
                                                     onResultsChange
                                                   }) => {
  const marketPriceDecimal = new bigDecimal(marketPrice);

  const isTakeProfit = data.secondTakeProfitPlacedPrice > 0 ? true : false;
  const dataFilledPrice = data.firstFilledPrice;
  const dataPrice = isTakeProfit ? bigDecimal.stripTrailingZero(bigDecimal.round(data.secondTakeProfitPlacedPrice, data.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(data.secondStopLossPlacedPrice, data.counterRound));

  const buyResults = CalculateMarketBuyData([data], counterEarning, marketPriceDecimal);
  const sellResults = CalculateMarketSellData([data], counterEarning, marketPriceDecimal);

  const currentSymbolValue = data.symbol.counterCurrency;
  const profitValue = tabType === MarketsTabsType.sell ? sellResults.profitTakeProfitStopLossValue : buyResults.profitTakeProfitStopLossValue;
  const profitPercentValue = tabType === MarketsTabsType.sell ? Number(sellResults.profitTakeProfitStopLossPercent.getValue()) : Number(buyResults.profitTakeProfitStopLossPercent.getValue());
  const averageQuantity = tabType === MarketsTabsType.sell ? sellResults.averageQuantityThird : buyResults.averageQuantityThird;
  const averageQuantityRemain = averageQuantity > 0 ? 100 - averageQuantity : 0;

  const value = tabType === MarketsTabsType.sell ?
    sellResults.valueThird.compareTo(new bigDecimal(sellResults.valueFourth)) !== 0 ? Number(sellResults.valueThird.subtract(new bigDecimal(sellResults.valueFourth)).getValue()) : Number(new bigDecimal('0').getValue()) :
    buyResults.valueThird.compareTo(new bigDecimal(buyResults.valueFourth)) !== 0 ? Number(buyResults.valueThird.subtract(new bigDecimal(buyResults.valueFourth)).getValue()) : Number(new bigDecimal('0').getValue())

  const id = data.blockId
  const filledQuantity = Number(data.firstFilledQuantity)
  const firstOrderId = Number(data.firstOrderId)


  useEffect(() => {

    if (onResultsChange) {
      onResultsChange({id, firstOrderId, value, profitValue, filledQuantity});
    }
  }, [value, profitValue, filledQuantity]);

  return (
    <Row>
      <Col>
        <div className='markets-table__row-main--collapsed ms-2'></div>
      </Col>
      <Col xs={9}>
        <Row className='markets-table__row subrow mt-1'>
          <Col></Col>
          <Col>
            <p style={{color: '#5b6aff'}}>{dataFilledPrice}</p>
            <p style={{color: '#ff363a'}}>{dataPrice}</p>
          </Col>
          <Col>{data.connectorName}</Col>
          <Col>{value} {currentSymbolValue}</Col>
          {tabType === 'buy'
            ? <Col
              xs={4} md
              className={`${profitValue > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profitValue > 0 ? "+" + profitValue : profitValue} 
                ${counterEarning ?
              data.symbol.counterCurrency :
              Number(new bigDecimal(marketPrice).getValue()) === 0 ? data.symbol.counterCurrency :
                Number(new bigDecimal(data.secondTakeProfitPlacedQuantity ? data.secondTakeProfitPlacedQuantity : data.secondStopLossPlacedQuantity)
                  .multiply(new bigDecimal(marketPrice))
                  .round(4, bigDecimal.RoundingModes.DOWN)
                  .subtract(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                  ).getValue()) < Number(new bigDecimal(data.baseQuantityStep).multiply(new bigDecimal(marketPrice)).getValue()) ?
                  data.symbol.counterCurrency :
                  data.symbol.baseCurrency}`}
            </Col>
            : <Col
              xs={4} md
              className={`${profitValue > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profitValue > 0 ? "+" + profitValue : profitValue}
                 ${counterEarning ?
              profitValue >= 0 ? data.symbol.counterCurrency : data.symbol.baseCurrency :
              Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                .subtract(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                  .multiply(new bigDecimal(marketPrice)).round(4, bigDecimal.RoundingModes.DOWN)).getValue()) >= 0 &&
              Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                .subtract(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                  .multiply(new bigDecimal(marketPrice)).round(4, bigDecimal.RoundingModes.DOWN)).getValue()) < Number(new bigDecimal(data.baseQuantityStep).multiply(new bigDecimal(marketPrice)).round(4, bigDecimal.RoundingModes.DOWN).getValue()) ? data.symbol.counterCurrency : data.symbol.baseCurrency}`}
            </Col>
          }
          {Number(profitPercentValue) > 0
            ? <Col xs={4} md className='text-success my-1 my-md-0'>{`+${Number(profitPercentValue)}%`}</Col>
            : <Col xs={4} md className='text-danger my-1 my-md-0'>{`${Number(profitPercentValue)}%`}</Col>
          }
          <Col
            className={tabType === MarketsTabsType.buy ? 'text-success' : 'text-danger'}>{capitalize(data.section.toLowerCase())}</Col>
          <Col>
            <ProgressBar data-bs-theme='dark'>
              <ProgressBar variant="success" now={averageQuantity} label={showProgress(averageQuantity)} key={1}/>
              <ProgressBar variant="danger" now={averageQuantityRemain} label={showProgress(averageQuantityRemain)}
                           key={2}/>
            </ProgressBar>
          </Col>
          <Col>
            {getBackArrowIcon()}
          </Col>
        </Row>
      </Col>
      <Col xs={2}></Col>
    </Row>
  );
};

export default MarketsTableThirdSubRow;