import React, { FC, useState } from 'react';
import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import bigDecimal from "js-big-decimal";
import { toast } from "react-toastify";

import { MarketsTableInput } from "../../MarketsTable/MarketsTableInput/MarketsTableInput";
import useNewOrderStore from "../../../services/hooks/useNewOrderStore";

interface Props {
  show: boolean,
  onClose: () => void
  type: string
  coinPair: string
  entryPrice: string
  symbol: string
  baseSymbol: string
  dataFilledPrice: string
  filledCounterQuantity: number
  currentSymbolValue: string
  marketPrice: number
  counterRound: number
  baseRound: number
  counterStep: number
  baseStep: number
  counterQuantity: number
  id: number
  tp?: string | undefined
  sl?: string | undefined
  counterEarning?: boolean
}

export const ModalProfitLoss: FC<Props> = (
  {
    show,
    onClose,
    type,
    coinPair,
    entryPrice,
    symbol,
    baseSymbol,
    dataFilledPrice,
    filledCounterQuantity,
    currentSymbolValue,
    marketPrice,
    counterRound,
    baseRound,
    counterStep,
    baseStep,
    counterQuantity,
    id,
    tp,
    sl,
    counterEarning = true,
  }) => {

  const [error, setError] = useState<boolean>(false);
  const [stopLossFocus, setStopLossFocus] = useState<boolean>(false);
  const [takeProfitFocus, setTakeProfitFocus] = useState<boolean>(false);
  const [stopLoss, setStopLoss] = useState<string>(sl || "");
  const [takeProfit, setTakeProfit] = useState<string>(tp || "");

  const pnlLoss =
    stopLoss && Number(stopLoss) > 0 ?
      type === 'buy' ?
        !counterEarning ?

          Number(new bigDecimal((filledCounterQuantity * Number(stopLoss) - counterQuantity) / Number(stopLoss)).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) >= baseStep ?
            Number(new bigDecimal((filledCounterQuantity * Number(stopLoss) - counterQuantity) / Number(stopLoss)).round(baseRound, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((filledCounterQuantity * Number(stopLoss) - counterQuantity)).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) :

          Number(bigDecimal.round((filledCounterQuantity * Number(stopLoss) - counterQuantity), counterRound)) :
        //type=sell
        !counterEarning ?
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 && Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) < baseStep ?
            Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((counterQuantity / Number(stopLoss))).round(baseRound, bigDecimal.RoundingModes.DOWN).getValue()) - Number(filledCounterQuantity) :
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 ?
            Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal(counterQuantity / Number(stopLoss)).round(baseRound, bigDecimal.RoundingModes.DOWN).getValue()) - Number(filledCounterQuantity) :
      0

  const pnlLossPercent =
    stopLoss && Number(stopLoss) > 0 ?
      type === 'buy' ?
        !counterEarning && ((filledCounterQuantity * Number(stopLoss) - counterQuantity) / Number(stopLoss)) >= baseStep ?
          Number(new bigDecimal((Number(pnlLoss) / filledCounterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
          Number(new bigDecimal((Number(pnlLoss) / counterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
        //type=sell
        !counterEarning ?
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 && Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) < baseStep ?
            Number(new bigDecimal((Number(pnlLoss) / counterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((Number(pnlLoss) / filledCounterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 ?
            Number(new bigDecimal((Number(pnlLoss) / counterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((Number(pnlLoss) / filledCounterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
      0

  const pnlProfit =
    takeProfit && Number(takeProfit) > 0 ?
      type === 'buy' ?
        !counterEarning ?
          Number(new bigDecimal((filledCounterQuantity * Number(takeProfit) - counterQuantity) / Number(takeProfit)).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) >= baseStep ?
            Number(new bigDecimal((filledCounterQuantity * Number(takeProfit) - counterQuantity) / Number(takeProfit)).round(baseRound, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((filledCounterQuantity * Number(takeProfit) - counterQuantity)).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) :

          Number(bigDecimal.round((filledCounterQuantity * Number(takeProfit) - counterQuantity), counterRound)) :
        //type=sell
        !counterEarning ?
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 && Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) < baseStep ?
            Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((counterQuantity / Number(takeProfit))).round(baseRound, bigDecimal.RoundingModes.DOWN).getValue()) - Number(filledCounterQuantity) :
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 ?
            Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal(counterQuantity / Number(takeProfit)).round(baseRound, bigDecimal.RoundingModes.DOWN).getValue()) - Number(filledCounterQuantity) :
      0

  const pnlProfitPercent =
    takeProfit && Number(takeProfit) > 0 ?
      type === 'buy' ?
        !counterEarning && ((filledCounterQuantity * Number(takeProfit) - counterQuantity) / Number(stopLoss)) >= baseStep ?
          Number(new bigDecimal((Number(pnlProfit) / filledCounterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
          Number(new bigDecimal((Number(pnlProfit) / counterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
        //type=sell
        !counterEarning ?
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 && Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) < baseStep ?
            Number(new bigDecimal((Number(pnlProfit) / counterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((Number(pnlProfit) / filledCounterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
          Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 ?
            Number(new bigDecimal((Number(pnlProfit) / counterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
            Number(new bigDecimal((Number(pnlProfit) / filledCounterQuantity) * 100).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
      0

  function handleDataLoss(data: any) {
    setStopLoss(data.newValue);
    setStopLossFocus(data.focused);
    setTakeProfitFocus(false);
  }

  function handleDataProfit(data: any) {
    setTakeProfit(data.newValue);
    setTakeProfitFocus(data.focused);
    setStopLossFocus(false);
  }

  const handleReset = () => {
    setError(false);
  };

  const handleSubmit = () => {

    const data = {
      "market": "binance",
      "symbolName": coinPair,
      "blockId": id,
      "takeProfitPrice": takeProfit !== "" ? takeProfit : 0,
      "stopLossPrice": stopLoss !== "" ? stopLoss : 0
    };

    fetch('/api/markets/spot/third', {
      method: (takeProfit && tp !== "") && (stopLoss && sl !== "") ? 'PUT' : takeProfit && tp !== "" ? 'PUT' : stopLoss && sl !== "" ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          return response.json().then((error) => {
            console.error('Server error:', error);
          });
        }
      })
      .then(responseData => {

        console.log('Response Body:', responseData);

        if (responseData.errorMessage === null) {
          onClose();

          const {addTpSlOrder} = useNewOrderStore.getState();

          responseData.orderBlocks.forEach((orderBlock: any) => {
            const updateOrderData = {
              baseQuantityStep: orderBlock.symbol.baseQuantityStep,
              baseRound: 0,
              blockId: orderBlock.blockId,
              counterEarning: orderBlock.counterEarning,
              counterRound: orderBlock.symbol.counterRound,
              firstFilledCounterQuantity: 0,
              firstFilledPrice: orderBlock.firstFilledPrice,
              firstFilledQuantity: orderBlock.firstFilledQuantity,
              firstFilledTime: orderBlock.firstFilledTime,
              firstFilledUnrealizedQuantity: orderBlock.firstFilledUnrealizedQuantity,
              firstOrderId: orderBlock.firstOrderId,
              firstPlacedPrice: orderBlock.firstPlacedPrice,
              firstPlacedQuantity: orderBlock.firstPlacedQuantity,
              firstPlacedTime: orderBlock.firstPlacedTime,
              open: orderBlock.open,
              secondFirstStopLossFilledCounterQuantity: 0,
              secondFirstStopLossPlacedCounterQuantity: 0,
              secondFirstStopLossPlacedQuantity: orderBlock.secondFirstStopLossPlacedQuantity ? orderBlock.secondFirstStopLossPlacedQuantity : 0,
              secondFirstTakeProfitFilledCounterQuantity: 0,
              secondFirstTakeProfitPlacedCounterQuantity: 0,
              secondFirstTakeProfitPlacedQuantity: orderBlock.secondFirstTakeProfitPlacedQuantity ? orderBlock.secondFirstTakeProfitPlacedQuantity : 0,
              secondStopLossFilledCounterQuantity: 0,
              secondStopLossFilledPrice: 0,
              secondStopLossFilledQuantity: 0,
              secondStopLossFilledTime: 0,
              secondStopLossOrderId: 0,
              secondStopLossPlacedCounterQuantity: 0,
              secondStopLossPlacedPrice: orderBlock.secondStopLossPlacedPrice ? orderBlock.secondStopLossPlacedPrice : 0,
              secondStopLossPlacedQuantity: orderBlock.secondStopLossPlacedQuantity ? orderBlock.secondStopLossPlacedQuantity : 0,
              secondStopLossPlacedTime: orderBlock.secondStopLossPlacedTime ? orderBlock.secondStopLossPlacedTime : 0,
              secondTakeProfitFilledCounterQuantity: 0,
              secondTakeProfitFilledPrice: 0,
              secondTakeProfitFilledQuantity: 0,
              secondTakeProfitFilledTime: 0,
              secondTakeProfitOrderId: 0,
              secondTakeProfitPlacedCounterQuantity: 0,
              secondTakeProfitPlacedPrice: orderBlock.secondTakeProfitPlacedPrice ? orderBlock.secondTakeProfitPlacedPrice : 0,
              secondTakeProfitPlacedQuantity: orderBlock.secondTakeProfitPlacedQuantity ? orderBlock.secondTakeProfitPlacedQuantity : 0,
              secondTakeProfitPlacedTime: orderBlock.secondTakeProfitPlacedTime ? orderBlock.secondTakeProfitPlacedTime : 0,
              section: orderBlock.section,
              userName: orderBlock.userName,
              connectorName: orderBlock.connectorName,
              symbol: {
                name: orderBlock.symbol.name,
                market: orderBlock.symbol.market,
                baseStepSize: orderBlock.symbol.baseStepSize,
                counterStepSize: orderBlock.symbol.counterStepSize,
                minCounterQuantity: orderBlock.symbol.minCounterQuantity,
                baseCurrency: orderBlock.symbol.baseCurrency,
                counterCurrency: orderBlock.symbol.counterCurrency,
                baseRound: orderBlock.symbol.baseRound,
                counterRound: orderBlock.symbol.counterRound,
                baseQuantityStep: orderBlock.symbol.baseQuantityStep,
              }
            };

            addTpSlOrder(orderBlock.blockId, updateOrderData);
          });

          toast.update(id, {
            render: 'Stop loss/take profit successfully placed',
            type: 'success',
            autoClose: 5000,
            isLoading: false
          });
        } else {
          setError(true);
          toast.update(id, {
            render: responseData.errorMessage,
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        }
        // Handle the data
      })
      .catch((error) => {
        toast.update(id, {
          render: error.message,
          type: 'warning',
          autoClose: 5000,
          isLoading: false
        });
        console.error('Network error:', error)
      });
  }

  const buySubmit = (stopLoss !== "" && Number(stopLoss) <= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - counterStep * 3, counterRound))) && takeProfit === "") ||
    (stopLoss !== "" && Number(stopLoss) <= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - counterStep * 3, counterRound))) && takeProfit !== "" && Number(takeProfit) >= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + counterStep * 3, counterRound)))) ||
    (takeProfit !== "" && Number(takeProfit) >= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + counterStep * 3, counterRound))) && stopLoss === '')

  const sellSubmit = (stopLoss !== "" && Number(stopLoss) >= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - counterStep * 3, counterRound))) && takeProfit === "") ||
    (stopLoss !== "" && Number(stopLoss) >= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - counterStep * 3, counterRound))) && takeProfit !== "" && Number(takeProfit) <= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + counterStep * 3, counterRound)))) ||
    (takeProfit !== "" && Number(takeProfit) <= Number(bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + counterStep * 3, counterRound))) && stopLoss === '')

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' size='lg' fullscreen='sm-down' centered>
      <Modal.Header closeButton className='text-center align-items-start'>
        <Modal.Title className='mx-auto pt-4 pb-2'>Stop Limit / Take Profit</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body__tpsl'>
        <Stack direction='horizontal' gap={3}>
          <Stack direction='vertical' className='calculator'>
            <Row className='align-items-center pb-3'>
              <Col className="fs-7">Position</Col>
              <Col
                className={`fw-bold justify-content-end d-flex text-capitalize ${type === "buy" ? 'text-success' : 'text-danger'}`}
                style={{paddingRight: '30px'}}>{type}</Col>
            </Row>

            <Row className='align-items-center pb-3'>
              <Col className="fs-7">Coin pair</Col>
              <Col className='text-white fw-bold justify-content-end d-flex'
                   style={{paddingRight: '30px'}}>{coinPair}</Col>
            </Row>

            <Row className='align-items-center pb-4 position-relative'>
              <Col className="fs-7 text-white" style={{paddingRight: 0}}>Stop&nbsp;Limit</Col>
              <Col style={{paddingLeft: 0}}>
                <label
                  className={`rounded-5 fw-bold px-3 gap-2 d-flex align-items-center border text-white ${stopLossFocus && pnlLoss >= 0 ? "border-1 border-danger" : "border-1 border-dark"}`}>
                  <MarketsTableInput
                    classname="border-0 bg-transparent text-right text-white"
                    counterRound={counterRound}
                    entryPrice={entryPrice}
                    inputValue={stopLoss}
                    resetValue={error ? stopLoss : undefined}
                    onReset={handleReset}
                    sendData={handleDataLoss}
                  />
                  <span>{currentSymbolValue}</span></label>
                <div
                  className="text-danger fs-7 text-end position-absolute error">{type === 'sell' ? 'min ' + bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + counterStep * 3, counterRound)) : 'max ' + bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - counterStep * 3, counterRound))}</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <hr className='border-1 border-bottom border-secondary-subtle'/>
              </Col>
            </Row>

            <Row className='align-items-center pt-3'>
              <Col className="fs-7 ">PNL</Col>
              <Col className={`d-flex gap-2 fw-bold ${pnlLoss > 0 ? 'text-success' : 'text-danger'}`}>
                <div>{pnlLoss}&nbsp;
                  {type === 'buy' ?
                    !counterEarning && ((filledCounterQuantity * Number(stopLoss) - counterQuantity) / Number(stopLoss)) >= baseStep ?
                      symbol :
                      currentSymbolValue :
                    //type=sell
                    !counterEarning ?
                      Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 && Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) < baseStep ?
                        currentSymbolValue :
                        baseSymbol :
                      Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(stopLoss))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 ?
                        currentSymbolValue :
                        baseSymbol}
                </div>
                {pnlLossPercent && <div>{pnlLossPercent}%</div>}
              </Col>
            </Row>
          </Stack>

          <Stack className='calculator'>
            <Row className='align-items-center pb-3'>
              <Col className="fs-7">Market price</Col>
              <Col className='text-primary fw-bold justify-content-end d-flex'
                   style={{paddingRight: '30px'}}>{marketPrice} {currentSymbolValue}</Col>
            </Row>

            <Row className='align-items-center pb-3'>
              <Col className="fs-7">Entry price</Col>
              <Col className='text-white fw-bold justify-content-end d-flex'
                   style={{paddingRight: '30px'}}>{dataFilledPrice} {currentSymbolValue}</Col>
            </Row>

            <Row className='align-items-center pb-3 d-flex pb-4 position-relative'>
              <Col className="fs-7" style={{paddingRight: 0}}>Take&nbsp;profit</Col>
              <Col style={{paddingLeft: 0}}>
                <label
                  className={`rounded-5 fw-bold px-3 gap-2 d-flex align-items-center border text-white ${takeProfitFocus && pnlProfit <= 0 ? "border-1 border-danger" : "border-1 border-dark"}`}>
                  <MarketsTableInput
                    classname="border-0 bg-transparent text-right text-white"
                    counterRound={counterRound}
                    entryPrice={dataFilledPrice}
                    inputValue={takeProfit}
                    resetValue={error ? takeProfit : undefined}
                    onReset={handleReset}
                    sendData={handleDataProfit}
                  />
                  <span>{currentSymbolValue}</span></label>
                <div
                  className="text-danger fs-7 text-end position-absolute error">{type === 'sell' ? 'max ' + bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - counterStep * 3, counterRound)) : 'min ' + bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + counterStep * 3, counterRound))}</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <hr className='border-1 border-bottom border-secondary-subtle'/>
              </Col>
            </Row>

            <Row className='align-items-center pt-3'>
              <Col>PNL</Col>
              <Col className={` d-flex gap-2 fw-bold ${pnlProfit > 0 ? "text-success" : "text-danger"}`}>
                <div>{pnlProfit}&nbsp;
                  {type === 'buy' ?
                    !counterEarning && ((filledCounterQuantity * Number(takeProfit) - counterQuantity) / Number(stopLoss)) >= baseStep ?
                      symbol :
                      currentSymbolValue :
                    //type=sell
                    !counterEarning ?
                      Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 && Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) < baseStep ?
                        currentSymbolValue :
                        baseSymbol :
                      Number(new bigDecimal(counterQuantity - (filledCounterQuantity * Number(takeProfit))).round(counterRound, bigDecimal.RoundingModes.DOWN).getValue()) > 0 ?
                        currentSymbolValue :
                        baseSymbol}
                </div>
                {pnlProfitPercent &&
                    <div>{pnlProfitPercent}%</div>}
              </Col>
            </Row>
          </Stack>

        </Stack>
      </Modal.Body>

      <Modal.Footer className='modal-footer__tpsl justify-content-center'>

        <Button
          variant='primary'
          onClick={handleSubmit}
          className='rounded-5 mr-3 btn-confirm'
          style={{marginLeft: '-45px'}}
          disabled={type === 'sell' ?
            sellSubmit ? false : true :
            buySubmit ? false : true}
        >
          OK
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
          className='rounded-5 mx-3 btn-cancel'
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
