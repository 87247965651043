import { create } from 'zustand';
import { BalanceTypeBody, OrderData } from "../../types/types";

interface GroupInfo {
  inGroup: boolean;
}

interface RemoveOrderData {
  blockId: number;
}

interface StoreState {
  userBalance: BalanceTypeBody | null;
  setUserBalance: (balance: BalanceTypeBody) => void;
  newOrder: Record<number, OrderData[]>;
  addNewOrder: (blockId: number, orderData: OrderData) => void;
  clearNewOrder: () => void;
  filledOrder: Record<number, OrderData[]>;
  addFilledOrder: (blockId: number, orderData: OrderData) => void;
  clearFilledOrder: () => void;
  tpSlOrder: Record<number, OrderData[]>;
  addTpSlOrder: (blockId: number, orderData: OrderData) => void;
  clearTpSlOrder: () => void;
  addChildOrder: (blockId: number, childOrderData: OrderData) => void;
  removeOrder: RemoveOrderData | null;
  setRemoveOrder: (removeOrder: RemoveOrderData | null) => void;
  groupInfo: Record<number, GroupInfo | null>;
  setGroupInfo: (blockId: number, groupInfo: GroupInfo | null) => void;
}

const useNewOrderStore = create<StoreState>((set) => ({
  userBalance: null,
  setUserBalance: (balance) => set({userBalance: balance}),
  newOrder: {},
  addNewOrder: (blockId, orderData) => set((state) => ({
    newOrder: {
      ...state.newOrder,
      [blockId]: [...(state.newOrder[blockId] || []), orderData],
    },
  })),
  addChildOrder: (blockId, childOrderData) => set((state) => ({
    newOrder: {
      ...state.newOrder,
      [blockId]: [...(state.newOrder[blockId] || []), childOrderData],
    },
  })),
  clearNewOrder: () => set({newOrder: {}}),
  filledOrder: {},
  addFilledOrder: (blockId, orderData) => set((state) => ({
    filledOrder: {
      ...state.filledOrder,
      [blockId]: [...(state.filledOrder[blockId] || []), orderData],
    },
  })),
  clearFilledOrder: () => set({filledOrder: {}}),
  tpSlOrder: {},
  addTpSlOrder: (blockId, orderData) => set((state) => ({
    tpSlOrder: {
      ...state.tpSlOrder,
      [blockId]: [...(state.tpSlOrder[blockId] || []), orderData],
    },
  })),
  clearTpSlOrder: () => set({tpSlOrder: {}}),
  removeOrder: null,
  setRemoveOrder: (removeOrder) => set({removeOrder: removeOrder, newOrder: {}}),
  groupInfo: {}, // Initialize groupInfo as an empty object
  setGroupInfo: (blockId, groupInfo) => set((state) => ({
    groupInfo: {
      ...state.groupInfo,
      [blockId]: groupInfo, // Set groupInfo for the specific blockId
    },
  })),
}));

export default useNewOrderStore;
