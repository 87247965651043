import React, { FC, useEffect, useId, useState } from 'react';
import { Col, Collapse, ProgressBar, Row, Stack } from 'react-bootstrap';
import bigDecimal from 'js-big-decimal';
import { toast } from "react-toastify";
import classNames from 'classnames';

import { capitalize, milisecondsToDate } from '../../../utils/helpers';
import { MarketsTabsType } from '../../../types/enums';
import { MarketsTableInput } from '../MarketsTableInput/MarketsTableInput';
import { MarketsTableFirstSubRow } from "../MarketsTableSubRow/MarketsTableFirstSubRow";
import { getTrashIcon } from "../../../services/icons";
import useNewOrderStore from "../../../services/hooks/useNewOrderStore";

interface Props {
  id: number,
  data: any,
  counterEarning: boolean,
  tabType: MarketsTabsType,
  value?: number,
  isRed?: boolean,
  show?: boolean,
  marketPrice?: number,
  groupData?: any,
}

export const MarketsTableRowFirstPosition: FC<Props> = ({
                                                          id,
                                                          data,
                                                          counterEarning,
                                                          tabType,
                                                          value,
                                                          isRed = false,
                                                          show,
                                                          groupData,
                                                          marketPrice,
                                                        }) => {
  const [open, setOpen] = useState(false);

  const [groupResult, setGroupResult] = useState<{
    id: number,
    firstOrderId: number,
    value: number,
    profitValue: number,
    filledQuantity: number,
  }[]>([]);

  const collapseId = useId();

  const dateString = milisecondsToDate(data.firstPlacedTime);
  const dataBuyCreationPrice = bigDecimal.stripTrailingZero(bigDecimal.round(data.firstPlacedPrice, data.counterRound));
  const currentSymbol = data.symbol.counterCurrency;

  const removePosition = async () => {
    const dataRemove = {
      "market": data.symbol.market.toLowerCase(),
      "symbolName": data.symbol.name.toLowerCase(),
      "blockId": data.blockId
    };

    try {
      const id = toast.loading("Please wait...", {closeButton: true});
      const response = await fetch(`/api/markets/spot/first/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataRemove)
      });


      if (response.ok) {
        const {setRemoveOrder} = useNewOrderStore.getState();
        setRemoveOrder(data.blockId);
        toast.update(id, {
          render: 'Order successfully removed',
          type: 'success',
          autoClose: 5000,
          isLoading: false
        });
      } else {
        const errorData = await response.json();
        // Handle server-side validation errors or other server responses here
        console.error('Server error:', errorData);
        toast.update(id, {
          render: 'An error occurred while deleting the order',
          type: 'error',
          autoClose: 5000,
          isLoading: false
        });
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  }

  const handleResultsChange = (newResult: {
    id: number,
    firstOrderId: number,
    value: number,
    profitValue: number,
    filledQuantity: number
  }) => {
    setGroupResult(prevResults => {
      const existingResultIndex = prevResults.findIndex(r => r.firstOrderId === newResult.firstOrderId);

      // If the result already exists, update it, otherwise add a new result
      if (existingResultIndex > -1) {
        // Update the existing result
        const updatedResults = [...prevResults];
        updatedResults[existingResultIndex] = {...newResult};
        return updatedResults;
      } else {
        // Add new result to the array
        return [...prevResults, {...newResult}];
      }
    });
  };

  const groupValue = groupResult.filter(result => result.id === id).reduce((sum, result) => sum + result.value, 0);

  useEffect(() => {
    setGroupResult([]);
  }, [counterEarning]);

  const {groupInfo} = useNewOrderStore();


  return (
    <div style={{border: isRed ? '1px solid red' : ''}} className={show ? "" : "d-none"}>
      <Row className='align-items-center justify-content-center fw-bold mt-2'>
        <Col
          onClick={groupData.length > 0 ? () => setOpen(!open) : undefined}
          aria-controls={groupData.length > 0 ? collapseId : ''}
          aria-expanded={groupData.length > 0 ? open : undefined}
          style={groupData.length > 0 || groupInfo ? {cursor: 'pointer'} : {cursor: 'default'}}
          className='ms-md-2 my-3 my-md-0'
          xs={3} md
        >
          <div className={classNames('markets-table__row-main', {open})}>
            <div className={classNames('markets-table__status', {
              'markets-table__status--blue': tabType === MarketsTabsType.buy || tabType === MarketsTabsType.all,
              'markets-table__status--orange': tabType === MarketsTabsType.sell,
              'three': groupData.length > 0 || groupInfo[id],
              'empty': groupData.length === 0 && !groupInfo[id],
            })}></div>
          </div>
        </Col>
        <Col md={9} className='mb-3 mb-md-0'>
          <Row className='markets-table__row py-1'>
            <Col xs={4} md className='my-1 my-md-0'>{dateString}</Col>
            <Col style={{color: '#5b6aff'}} xs={4} md className='my-2 my-md-0'>{dataBuyCreationPrice}</Col>
            <Col xs={4} md className='my-1 my-md-0'>{data.symbol.name}</Col>
            <Col xs={4} md
                 className='my-1 my-md-0'>{`${groupData.length > 0 ? Number(new bigDecimal(groupValue).round(4, bigDecimal.RoundingModes.DOWN).getValue()) : value} ${currentSymbol}`}</Col>
            <Col xs={4} md className='text-danger my-1 my-md-0'></Col>
            <Col xs={4} md className='text-danger my-1 my-md-0'></Col>
            <Col xs={4} md
                 className={`${tabType === 'buy' ? 'text-success my-1 my-md-0' : 'text-danger my-1 my-md-0'}`}>{capitalize(tabType)}</Col>
            <Col xs={4} md className='my-1 my-md-0'>
              <ProgressBar data-bs-theme='dark'>
                <ProgressBar variant="success" now={0} key={1}/>
                <ProgressBar variant="danger" now={0} key={2}/>
              </ProgressBar>
            </Col>
            <Col xs={4} md className='my-1 my-md-0'>
              <button type="button" className="" style={{backgroundColor: "transparent"}} onClick={removePosition}>
                {getTrashIcon("#ffffff", "13", "14")}
              </button>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <Stack direction="horizontal" className='markets-table__inputwrapper'>
            <MarketsTableInput inputValue={dataBuyCreationPrice} counterRound={data.counterRound}/>
            <button className='markets-table__button'>
              Price
            </button>
          </Stack>
        </Col>
      </Row>
      {groupData.length > 0 && groupData.map((subRow: any, index: number) => {
        return (
          <Collapse in={open} key={index}>
            <div id={collapseId}>
              <MarketsTableFirstSubRow
                key={index}
                data={subRow}
                tabType={subRow.section.toLowerCase()}
                currentSymbol={currentSymbol}
                counterEarning={counterEarning}
                counterRound={data.counterRound}
                onResultsChange={handleResultsChange}
              />
            </div>
          </Collapse>
        )
      })}
    </div>
  );
};
