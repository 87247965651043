import React, { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';

import './ControlApi.scss';
import { ModalAddApi } from '../Modals/ModalAddApi/ModalAddApi';
import imgButtonPlayVideo from '../../assets/images/button_play_video.png';
import { ControlApiCard } from "./ControlApiCard/ControlApiCard";
import { getInputCheckedIcon, getInputIcon } from "../../services/icons";
import { ModalVideo } from "../Modals/ModalVideo/ModalVideo";
import useAPIStore from "../../services/hooks/useFetchedDataStore";
import useUserStore from "../../services/hooks/useUserStore";

const bgElement = new URL("../../assets/images/105.png", import.meta.url);

export const ControlApi = () => {
  const [showModalAddAlert, setShowModalAddAlert] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [mainId, setMainId] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState(false);
  const openModal = () => {
    setShowVideo(!showVideo);
  };
  const {fetchedData, fetchData,} = useAPIStore();
  const {userInfo, fetchUserData} = useUserStore();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  async function addAPi() {
    await fetchData();
  }

  async function handleChangeActive() {
    setIsChanging(true)
    setMainId(fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid);
  }

  async function handleChangeMainId() {
    setMainId(fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid);
    const data = {
      "uid": fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid,
      "replaceUid": mainId,
      "market": "BINANCE"
    };

    setIsLoading(true);

    try {
      const response = await fetch('/api/key/replace', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setIsChanging(false);
        setIsLoading(false);
        await fetchData();
      } else {
        const errorData = await response.json();
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
      setIsLoading(false);
    }
  }

  return (
    <main className='position-relative' style={{maxWidth: '78rem', margin: '0 auto', padding: '0.5rem'}}>
      <img src={bgElement.href} alt=""
           style={{top: '200px', width: 'auto', height: 'auto', left: '-195px', zIndex: '-1'}}
           className='position-absolute'/>
      <Stack direction='horizontal' gap={3} className='account__bgfield flex-wrap flex-lg-nowrap mt-5'>
        <Stack direction='vertical' gap={3}>
          <h4>Add your control API</h4>
          Press “Add API Key” to add new API Keys and start working with them
        </Stack>
        <button className='header__button header__button--fill fw-bold px-5 py-3'
                onClick={() => setShowModalAddAlert(true)}
                style={{fontSize: '32px', minWidth: '220px', borderRadius: '40px'}}>Add
        </button>
      </Stack>

      {fetchedData?.apiKeyMarketGroups && fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey
        ? <Stack direction='vertical' gap={4} className='mt-5 mb-5'>
          <div className='d-flex justify-content-between px-4'>
            <h3>Your API now <span style={{color: '#3548FE'}}>{userInfo?.body.username}</span></h3>
            {isChanging
              ?
              <button className='header__button header__button--fill fw-bold px-5 py-2 text-black' disabled={isLoading}
                      style={{background: '#36FF6C'}} onClick={handleChangeMainId}>OK</button>
              : <button className='header__button header__button--fill fw-bold px-4 py-2'
                        onClick={handleChangeActive}>Change API</button>
            }
          </div>

          {fetchedData?.apiKeyMarketGroups && fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey && (
            <Stack direction='horizontal' gap={4}>
              {isChanging && (
                fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid === mainId
                  ? <div>
                    {getInputCheckedIcon()}
                  </div>
                  :
                  <div onClick={() => setMainId(fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid)}>
                    {getInputIcon()}
                  </div>
              )}

              {isChanging
                ? <div
                  className='w-100'
                  style={{cursor: 'pointer'}}
                >
                  <ControlApiCard
                    data={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup}
                    active={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid}
                  />
                </div>
                : <ControlApiCard
                  data={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup}
                  active={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid}
                />
              }
            </Stack>
          )}

          {fetchedData?.apiKeyMarketGroups && fetchedData?.apiKeyMarketGroups[0]?.apiKeyGroups.map((item: any, index: number) => {
            return <Stack direction='horizontal' gap={4} key={index}>
              {isChanging && (
                item.mainKey.uid === mainId
                  ? <div onClick={() => setMainId(item.mainKey.uid)}>
                    {getInputCheckedIcon()}
                  </div>
                  : <div onClick={() => setMainId(item.mainKey.uid)}>
                    {getInputIcon()}
                  </div>
              )}

              {isChanging
                ? <div className='w-100' style={{cursor: 'pointer'}}>
                  <ControlApiCard
                    key={index}
                    data={item}
                    active={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid}
                  />
                </div> :
                <ControlApiCard
                  key={index}
                  data={item}
                  active={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid}
                />
              }
            </Stack>
          })}
        </Stack>
        : <Stack
          direction='horizontal'
          gap={5}
          className='account__bgfield additional flex-wrap flex-lg-nowrap'
          style={{margin: '4.5rem 0'}}>
          <div className='controlapi__video' onClick={openModal}>
            <img src={imgButtonPlayVideo} alt='Play Video Button' className='controlapi__playbutton'/>
          </div>
          <Stack direction="vertical" gap={3} className='justify-content-center'>
            <h4>You have no API keys</h4>
            <p className='text-secondary'>How to add your control API</p>
            <ul>
              <li style={{listStyle: 'disc'}}>Copymaster provides clients with the most powerful, industry-leading API
                functionalities. You can use API to check market data, process automated trading orders and much more.
                See API Documentation page for more details.
              </li>
              <li style={{listStyle: 'disc'}}>Each account can have up to 20 keys.</li>
            </ul>
            <button className='header__button header__button--fill fw-bold py-3'
                    onClick={() => setShowModalAddAlert(true)}>Add
            </button>
          </Stack>
        </Stack>
      }
      {/*<Support/>*/}

      {showModalAddAlert &&
          <ModalAddApi
              show={showModalAddAlert}
              onClose={() => setShowModalAddAlert(false)}
              handler={addAPi}
          />
      }

      {showVideo &&
          <ModalVideo
              show={showVideo}
              onClose={() => setShowVideo(false)}
          />
      }
    </main>
  );
};