import { useEffect, useState } from 'react';
import bigDecimal from 'js-big-decimal';
import { takeAverage } from "../../../utils/helpers";

interface MarketData {
  blockId: number;
  firstFilledQuantity: string;
  takeProfitStopLossCounterQuantity: string;
  filledTakeProfitStopLossQuantity: string;
  buyFilledTakeProfitStopLossCounterQuantity: string;
  buyTakeProfitStopLossCounterQuantity: string;
  filledUnrealizedQuantity: string;
  secondFilledPrice: string;
  firstSecondPlacedQuantity: string;
  firstFilledPrice: string;
  firstPlacedPrice: string;
  baseRound: number;
  baseQuantityStep: number;
  counterRound: number;
  secondTakeProfitPlacedPrice: number;
  secondStopLossPlacedPrice: number;
  secondFirstTakeProfitPlacedQuantity: string;
  secondFirstStopLossPlacedQuantity: string;
  secondTakeProfitFilledPrice: number;
  secondStopLossFilledPrice: number;
  symbol: any;
}

interface CalculateMarketDataResult {
  valueFirst: bigDecimal;
  valueSecond: bigDecimal;
  valueThird: bigDecimal;
  valueFourth: string;
  averageQuantitySecond: number;
  profitQuantity: bigDecimal;
  profitPercentSecond: string;
  averageQuantityThird: number;
  profitTakeProfitStopLossQuantity: bigDecimal;
  profitTakeProfitStopLossValue: number;
  profitTakeProfitStopLossPercent: bigDecimal;
  averageQuantityFourth: number;
  profitFilledTakeProfitStopLossQuantity: number;
  profitFilledTakeProfitStopLossValue: number;
  profitFilledTakeProfitStopLossValuePercent: bigDecimal;
  resultSumFilledTakeProfitStopLossQuantityAdditional: bigDecimal;
  showFirstPosition: boolean;
  showSecondPosition: boolean;
  showThirdPosition: boolean;
  showFourthPosition: boolean;
  showCssLine: boolean;
}

export function CalculateMarketBuyData(
  data: MarketData[],
  counterEarning: boolean,
  marketPriceDecimal: bigDecimal,
): CalculateMarketDataResult {

  const [dataTakeProfitStopLossPlacedPrice, setDataTakeProfitStopLossPlacedPrice] = useState('');
  const [dataTakeProfitStopLossQuantity, setDataTakeProfitStopLossQuantity] = useState('');
  const [dataFilledTakeProfitStopLossCounterQuantity, setDataFilledTakeProfitStopLossCounterQuantity] = useState('');
  const [dataFilledTakeProfitStopLossQuantity, setDataFilledTakeProfitStopLossQuantity] = useState('');
  const [dataBuyFilledTakeProfitStopLossCounterQuantity, setDataBuyFilledTakeProfitStopLossCounterQuantity] = useState('');
  const [dataSecondFilledPrice, setDataSecondFilledPrice] = useState('');
  const [dataFirstSecondPlacedQuantity, setDataFirstSecondPlacedQuantity] = useState('');

  const dataQuantityStable = 'firstPlacedQuantity';
  const dataFilledQuantityStable = 'firstFilledQuantity';
  const dataFilledUnrealizedQuantity = 'firstFilledUnrealizedQuantity';
  const dataFirstFilledPrice = 'firstFilledPrice';
  const bigDecimalZero = new bigDecimal('0');

  const {
    sumQuantityStable,
    sumFilledQuantityStable,
    sumTakeProfitStopLossQuantity,
    sumFilledTakeProfitStopLossCounterQuantity,
    sumFilledTakeProfitStopLossQuantity,
    sumBuyFilledTakeProfitStopLossCounterQuantity,
    sumFilledUnrealizedQuantity,
    sumSecondFilledPrice,
    sumFirstFilledPrice,
    sumFirstSecondPlacedQuantity,
    sumDataTakeProfitStopLossPlacedPrice

  } = data.reduce((acc, item) => {
    const quantityStable = new bigDecimal(item[dataQuantityStable as keyof MarketData] as string);
    const filledQuantityStable = new bigDecimal(item[dataFilledQuantityStable as keyof MarketData] as string);
    const takeProfitStopLossQuantity = new bigDecimal(item[dataTakeProfitStopLossQuantity as keyof MarketData] as string);
    const takeFilledProfitStopLossCounterQuantity = new bigDecimal(item[dataFilledTakeProfitStopLossCounterQuantity as keyof MarketData] as string);
    const takeFilledProfitStopLossQuantity = new bigDecimal(item[dataFilledTakeProfitStopLossQuantity as keyof MarketData] as string);
    // @ts-ignore
    const buyFilledTakeProfitStopLossCounterQuantity = new bigDecimal(item[dataBuyFilledTakeProfitStopLossCounterQuantity as keyof MarketData] as string);
    const firstFilledPrice = new bigDecimal(item[dataFirstFilledPrice as keyof MarketData] as string);
    // @ts-ignore
    const filledUnrealizedQuantity = new bigDecimal(item[dataFilledUnrealizedQuantity as keyof MarketData] as string);
    const secondFilledPrice = new bigDecimal(item[dataSecondFilledPrice as keyof MarketData] as string);
    const firstSecondPlacedQuantity = new bigDecimal(item[dataFirstSecondPlacedQuantity as keyof MarketData] as string);
    // @ts-ignore
    const takeProfitStopLossPlacedPrice = new bigDecimal(item[dataTakeProfitStopLossPlacedPrice as keyof MarketData] as string);

    return {
      sumQuantityStable: acc.sumQuantityStable.add(quantityStable),
      sumFilledQuantityStable: acc.sumFilledQuantityStable.add(filledQuantityStable),
      sumTakeProfitStopLossQuantity: acc.sumTakeProfitStopLossQuantity.add(takeProfitStopLossQuantity),
      sumFilledTakeProfitStopLossCounterQuantity: acc.sumFilledTakeProfitStopLossCounterQuantity.add(takeFilledProfitStopLossCounterQuantity),
      sumFilledTakeProfitStopLossQuantity: acc.sumFilledTakeProfitStopLossQuantity.add(takeFilledProfitStopLossQuantity),
      sumBuyFilledTakeProfitStopLossCounterQuantity: acc.sumBuyFilledTakeProfitStopLossCounterQuantity.add(buyFilledTakeProfitStopLossCounterQuantity),
      sumFilledUnrealizedQuantity: acc.sumFilledUnrealizedQuantity.add(filledUnrealizedQuantity),
      sumSecondFilledPrice: acc.sumSecondFilledPrice.add(secondFilledPrice),
      sumFirstFilledPrice: acc.sumFirstFilledPrice.add(firstFilledPrice),
      sumFirstSecondPlacedQuantity: acc.sumFirstSecondPlacedQuantity.add(firstSecondPlacedQuantity),
      sumDataTakeProfitStopLossPlacedPrice: acc.sumDataTakeProfitStopLossPlacedPrice.add(takeProfitStopLossPlacedPrice),
    };
  }, {
    sumQuantityStable: bigDecimalZero,
    sumFilledQuantityStable: bigDecimalZero,
    sumTakeProfitStopLossQuantity: bigDecimalZero,
    sumFilledTakeProfitStopLossCounterQuantity: bigDecimalZero,
    sumFilledTakeProfitStopLossQuantity: bigDecimalZero,
    sumBuyFilledTakeProfitStopLossCounterQuantity: bigDecimalZero,
    sumFilledUnrealizedQuantity: bigDecimalZero,
    sumFirstFilledPrice: bigDecimalZero,
    sumSecondFilledPrice: bigDecimalZero,
    sumFirstSecondPlacedQuantity: bigDecimalZero,
    sumDataTakeProfitStopLossPlacedPrice: bigDecimalZero,
  });

  const valueThird = sumFirstSecondPlacedQuantity.multiply(new bigDecimal(data[0].firstFilledPrice));

  const valueSecond = Number(sumFilledUnrealizedQuantity.getValue()) !== 0
    ? sumFilledUnrealizedQuantity.multiply(sumFirstFilledPrice)
    : sumFilledQuantityStable.multiply(sumFirstFilledPrice);

  const valueFirst = valueThird.compareTo(valueSecond) !== 0
    ? sumQuantityStable.multiply(new bigDecimal(data[0].firstPlacedPrice)).subtract(valueSecond).subtract(valueThird).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
    : sumQuantityStable.multiply(new bigDecimal(data[0].firstPlacedPrice)).subtract(valueSecond).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN);

  const valueFourth = counterEarning
    ? sumFilledTakeProfitStopLossQuantity.multiply(new bigDecimal(data[0].firstFilledPrice)).getValue()
    : Number(sumFirstFilledPrice.getValue()) === 0
      ? 0 : sumTakeProfitStopLossQuantity.compareTo(sumFilledTakeProfitStopLossQuantity) === 0
        ? sumFirstSecondPlacedQuantity.multiply(new bigDecimal(data[0].firstFilledPrice)).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN).getValue()
        : sumFilledTakeProfitStopLossQuantity
          .multiply(sumDataTakeProfitStopLossPlacedPrice)
          .divide(sumFirstFilledPrice)
          .round(0, bigDecimal.RoundingModes.DOWN)
          .multiply(new bigDecimal(data[0].firstFilledPrice))
          .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
          .getValue();

  // calculate 1-st position
  const sumQuantity = sumQuantityStable;
  const sumFilledQuantity = sumFilledQuantityStable;

  // calculate 2-nd position
  const averageQuantitySecond = Number(sumQuantity.getValue()) === 0
    ? 0
    : takeAverage(
      Number(sumFilledUnrealizedQuantity.getValue()) > 0
        ? sumFilledUnrealizedQuantity.add(sumFirstSecondPlacedQuantity)
        : sumFilledQuantity,
      sumQuantity
    );

  const profitQuantity = Number(marketPriceDecimal.getValue()) === 0 || Number(data[0].firstFilledPrice) === 0
    ? bigDecimalZero
    : counterEarning
      ? valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
        .divide(new bigDecimal(data[0].firstFilledPrice))
        .multiply(marketPriceDecimal)
        .subtract(valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
        .round(4, bigDecimal.RoundingModes.DOWN)

      //base currency
      //if more than 0 but less than quantity step * marketprice
      : Number(
        (Number(sumFilledUnrealizedQuantity.getValue()) > 0
          ? sumFilledUnrealizedQuantity
          : sumFilledQuantityStable)
          .multiply(marketPriceDecimal)
          .round(4, bigDecimal.RoundingModes.DOWN)
          .subtract(valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
          .getValue()
      ) < Number(new bigDecimal(data[0].baseQuantityStep).multiply(marketPriceDecimal).getValue()) ?

        (Number(sumFilledUnrealizedQuantity.getValue()) > 0
          ? sumFilledUnrealizedQuantity
          : sumFilledQuantityStable)
          .multiply(marketPriceDecimal)
          .round(4, bigDecimal.RoundingModes.DOWN)
          .subtract(valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))

        //if more than baseStep currency
        : (Number(sumFilledUnrealizedQuantity.getValue()) > 0
          ? sumFilledUnrealizedQuantity
          : sumFilledQuantityStable)
          .subtract(
            valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
              .divide(marketPriceDecimal)
              .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
          )

  const profitPercentSecond = Number(sumFilledQuantityStable.add(sumFirstSecondPlacedQuantity).getValue()) === 0 || Number(valueSecond.getValue()) === 0 || Number(sumFilledQuantityStable.getValue()) === 0 || Number(marketPriceDecimal.getValue()) === 0 ? bigDecimalZero.getValue() :
    counterEarning ?
      profitQuantity.divide(valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)).multiply(new bigDecimal('100')).round(2, bigDecimal.RoundingModes.DOWN).getValue() :
      //base currency %
      Number(
        (Number(sumFilledUnrealizedQuantity.getValue()) > 0
          ? sumFilledUnrealizedQuantity
          : sumFilledQuantityStable)
          .multiply(marketPriceDecimal)
          .round(4, bigDecimal.RoundingModes.DOWN)
          .subtract(valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
          .getValue()
      ) < Number(new bigDecimal(data[0].baseQuantityStep).multiply(marketPriceDecimal).getValue())
        ? profitQuantity
          .divide(valueSecond.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN)
          .getValue() :
        profitQuantity
          .divide((Number(sumFilledUnrealizedQuantity.getValue()) > 0
            ? sumFilledUnrealizedQuantity
            : sumFilledQuantityStable))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN)
          .getValue();

  // calculate 3-rd position
  const averageQuantityThird = Number(sumQuantity.getValue()) === 0 || Number(sumFirstSecondPlacedQuantity.getValue()) === 0 || Number(sumFirstSecondPlacedQuantity.getValue()) === 0 ? 0 :
    counterEarning ?
      takeAverage(sumTakeProfitStopLossQuantity, sumFilledQuantityStable) :
      takeAverage(sumFirstSecondPlacedQuantity,
        Number(sumFilledUnrealizedQuantity.getValue()) > 0 ?
          sumFilledUnrealizedQuantity.add(sumFirstSecondPlacedQuantity) :
          sumFilledQuantity);

  const profitTakeProfitStopLossQuantity = Number(marketPriceDecimal.getValue()) === 0 || Number(new bigDecimal(data[0].firstFilledPrice).getValue()) === 0 ?
    bigDecimalZero :
    counterEarning ?
      valueThird.compareTo(new bigDecimal(valueFourth)) !== 0 ?
        valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
          .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
          .divide(new bigDecimal(data[0].firstFilledPrice))
          .multiply(marketPriceDecimal)
          .subtract(valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN).subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)))
          .round(4, bigDecimal.RoundingModes.DOWN) :
        bigDecimalZero
          .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN) :
      //base currency

      Number(sumTakeProfitStopLossQuantity
        .multiply(marketPriceDecimal)
        .round(4, bigDecimal.RoundingModes.DOWN)
        .subtract(valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
          .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
        ).getValue()) < Number(new bigDecimal(data[0].baseQuantityStep).multiply(marketPriceDecimal).getValue()) ?

        sumTakeProfitStopLossQuantity
          .multiply(marketPriceDecimal)
          .round(4, bigDecimal.RoundingModes.DOWN)
          .subtract(valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
            .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
          ) :
        sumTakeProfitStopLossQuantity
          .subtract(valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN).subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
            .divide(marketPriceDecimal))
          .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN);

  const profitTakeProfitStopLossValue = Number(profitTakeProfitStopLossQuantity.getValue());

  const profitTakeProfitStopLossPercent = Number(valueThird.getValue()) === 0 || Number(valueThird
    .subtract(new bigDecimal(valueFourth)).getValue()) === 0 || Number(sumFilledQuantityStable.getValue()) === 0 || Number(marketPriceDecimal.getValue()) === 0 ?
    bigDecimalZero :
    counterEarning ?
      valueThird.compareTo(new bigDecimal(valueFourth)) !== 0 ?
        profitTakeProfitStopLossQuantity
          .divide(valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
            .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN) :
        bigDecimalZero
          .round(2, bigDecimal.RoundingModes.DOWN) :
      //base currency
      Number(sumTakeProfitStopLossQuantity
        .multiply(marketPriceDecimal)
        .round(4, bigDecimal.RoundingModes.DOWN)
        .subtract(valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
          .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
        ).getValue()) < Number(new bigDecimal(data[0].baseQuantityStep).multiply(marketPriceDecimal).getValue()) ?
        profitTakeProfitStopLossQuantity
          .divide(valueThird.round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
            .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN) :
        Number(profitTakeProfitStopLossQuantity.getValue()) === 0 ? bigDecimalZero :
          profitTakeProfitStopLossQuantity
            .divide(sumTakeProfitStopLossQuantity)
            .multiply(new bigDecimal('100'))
            .round(2, bigDecimal.RoundingModes.DOWN)


  // calculate 4-th position
  const averageQuantityFourth = Number(sumQuantity.getValue()) === 0 || Number(sumTakeProfitStopLossQuantity.getValue()) === 0 || Number(sumFirstFilledPrice.getValue()) === 0 || Number(sumFirstSecondPlacedQuantity.getValue()) === 0 ? 0 :
    counterEarning ?
      takeAverage(sumFilledTakeProfitStopLossQuantity, sumTakeProfitStopLossQuantity) :
      sumTakeProfitStopLossQuantity.compareTo(sumFilledTakeProfitStopLossQuantity) === 0 ? 100 :
        takeAverage(
          sumFilledTakeProfitStopLossQuantity
            .multiply(new bigDecimal(data[0].secondTakeProfitFilledPrice > 0 ? data[0].secondTakeProfitFilledPrice : data[0].secondStopLossFilledPrice))
            .divide(sumFirstFilledPrice)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN),
          sumFirstSecondPlacedQuantity);

  const resultSumFilledTakeProfitStopLossQuantityAdditional = counterEarning ? sumBuyFilledTakeProfitStopLossCounterQuantity : sumFilledTakeProfitStopLossQuantity;

  const profitFilledTakeProfitStopLossQuantity = Number(marketPriceDecimal.getValue()) === 0 || Number(sumFirstSecondPlacedQuantity.getValue()) === 0 || Number(sumFirstFilledPrice.getValue()) === 0 || Number(sumFilledTakeProfitStopLossQuantity.getValue()) === 0 ? 0 :
    counterEarning ?
      sumSecondFilledPrice.multiply(sumFilledTakeProfitStopLossQuantity).subtract(sumFirstFilledPrice.multiply(sumFilledTakeProfitStopLossQuantity))
        .round(4, bigDecimal.RoundingModes.DOWN)
        .getValue()
      //base currency
      : Number(sumFilledTakeProfitStopLossQuantity
        .multiply(sumSecondFilledPrice)
        .round(4, bigDecimal.RoundingModes.DOWN)
        .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
        .getValue()) < Number(new bigDecimal(data[0].baseQuantityStep).multiply(marketPriceDecimal).getValue()) ?
        sumFilledTakeProfitStopLossQuantity
          .multiply(sumSecondFilledPrice)
          .round(4, bigDecimal.RoundingModes.DOWN)
          .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
          .getValue() :
        sumFilledTakeProfitStopLossQuantity
          .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN).divide(sumSecondFilledPrice).round(data[0].baseRound, bigDecimal.RoundingModes.DOWN))
          .getValue()

  const profitFilledTakeProfitStopLossValue = Number(profitFilledTakeProfitStopLossQuantity);

  const profitFilledTakeProfitStopLossValuePercent = Number(sumFirstSecondPlacedQuantity.getValue()) === 0 || Number(sumTakeProfitStopLossQuantity.getValue()) === 0 || Number(sumFilledTakeProfitStopLossQuantity.getValue()) === 0 || Number(new bigDecimal(valueFourth).getValue()) === 0 ?
    bigDecimalZero :
    counterEarning ?
      new bigDecimal(profitFilledTakeProfitStopLossQuantity)
        .divide(new bigDecimal(valueFourth))
        .multiply(new bigDecimal('100'))
        .round(2, bigDecimal.RoundingModes.DOWN) :
      //base currency
      Number(sumFilledTakeProfitStopLossQuantity
        .multiply(sumSecondFilledPrice)
        .round(4, bigDecimal.RoundingModes.DOWN)
        .subtract(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
        .getValue()) < Number(new bigDecimal(data[0].baseQuantityStep).multiply(marketPriceDecimal).getValue()) ?
        new bigDecimal(profitFilledTakeProfitStopLossValue)
          .divide(new bigDecimal(valueFourth).round(data[0].counterRound, bigDecimal.RoundingModes.DOWN))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN) :
        new bigDecimal(profitFilledTakeProfitStopLossValue)
          .divide(sumFilledTakeProfitStopLossQuantity)
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN);

  //Display positions
  const showFirstPosition = counterEarning ? Number(sumQuantityStable.getValue()) > Number(sumFilledQuantityStable.getValue()) : Number(valueFirst.getValue()) > Number(marketPriceDecimal.multiply(new bigDecimal(data[0].symbol.baseQuantityStep)).getValue());
  const showSecondPosition = counterEarning
    ? sumTakeProfitStopLossQuantity.compareTo(sumFilledQuantityStable) !== 0 :
    Number(sumFilledUnrealizedQuantity.getValue()) > 0 || (Number(sumFilledQuantityStable.getValue()) > 0 && Number(sumDataTakeProfitStopLossPlacedPrice.getValue()) === 0);
  const showThirdPosition = counterEarning
    ? sumFilledTakeProfitStopLossQuantity.compareTo(sumTakeProfitStopLossQuantity) !== 0
    : sumTakeProfitStopLossQuantity.compareTo(sumFilledTakeProfitStopLossQuantity) !== 0;
  const showFourthPosition = sumFilledTakeProfitStopLossQuantity.compareTo(bigDecimalZero) !== 0;
  const showCssLine = [showFirstPosition, showSecondPosition, showThirdPosition, showFourthPosition].filter(Boolean).length > 1;


  useEffect(() => {
    if (Number(data[0].secondTakeProfitPlacedPrice) > 0) {
      setDataTakeProfitStopLossPlacedPrice(() => 'secondTakeProfitPlacedPrice');
      setDataTakeProfitStopLossQuantity(() => 'secondTakeProfitPlacedQuantity');
      setDataFirstSecondPlacedQuantity(() => 'secondFirstTakeProfitPlacedQuantity');
    } else if (Number(data[0].secondStopLossPlacedPrice) > 0) {
      setDataTakeProfitStopLossPlacedPrice(() => 'secondStopLossPlacedPrice');
      setDataTakeProfitStopLossQuantity(() => 'secondStopLossPlacedQuantity');
      setDataFirstSecondPlacedQuantity(() => 'secondFirstStopLossPlacedQuantity');
    }

    if (Number(data[0].secondTakeProfitFilledPrice) > 0) {
      setDataFilledTakeProfitStopLossCounterQuantity(() => 'secondTakeProfitFilledCounterQuantity');
      setDataFilledTakeProfitStopLossQuantity(() => 'secondTakeProfitFilledQuantity');
      setDataBuyFilledTakeProfitStopLossCounterQuantity(() => 'secondFirstTakeProfitFilledCounterQuantity');
      setDataSecondFilledPrice(() => 'secondTakeProfitFilledPrice');
    } else if (Number(data[0].secondStopLossFilledPrice) > 0) {
      setDataFilledTakeProfitStopLossCounterQuantity(() => 'secondStopLossFilledCounterQuantity');
      setDataFilledTakeProfitStopLossQuantity(() => 'secondStopLossFilledQuantity');
      setDataBuyFilledTakeProfitStopLossCounterQuantity(() => 'secondFirstStopLossFilledCounterQuantity');
      setDataSecondFilledPrice(() => 'secondStopLossFilledPrice');
    }
  }, [counterEarning, data]);

  return {
    valueFirst,
    valueSecond,
    valueThird,
    valueFourth,
    averageQuantitySecond,
    profitQuantity,
    profitPercentSecond,
    averageQuantityThird,
    profitTakeProfitStopLossQuantity,
    profitTakeProfitStopLossValue,
    profitTakeProfitStopLossPercent,
    averageQuantityFourth,
    profitFilledTakeProfitStopLossQuantity,
    profitFilledTakeProfitStopLossValue,
    profitFilledTakeProfitStopLossValuePercent,
    resultSumFilledTakeProfitStopLossQuantityAdditional,
    showFirstPosition,
    showSecondPosition,
    showThirdPosition,
    showFourthPosition,
    showCssLine
  } as CalculateMarketDataResult;
}
