import React, { FC, useState } from 'react';
import { Col, Row, Stack } from "react-bootstrap";

import { getCrossEyeIcon, getEyeIcon, getPencilIcon } from "../../../services/icons";
import useFocus from "../../../hooks/useFocus";
import useAPIStore from "../../../services/hooks/useFetchedDataStore";


interface Props {
  data: any
  openRepeatAccounts: boolean;
  onRepeatAccountsToggle: (isOpen: boolean) => void;
  group: any;
  active: boolean;
}


export const UserApiCard: FC<Props> = ({data, openRepeatAccounts, onRepeatAccountsToggle, group, active}) => {
  const [apiName, setApiName] = useState<string>(data.name);
  const [apiKey, setApiKey] = useState<string>(data.key);
  const [readOnlyName, setReadOnlyName] = useState<boolean>(true);
  const [displayKey, setDisplayKey] = useState<boolean>(false);

  const [nameRef, setNameFocus] = useFocus<HTMLInputElement>();
  const {fetchData} = useAPIStore();

  function handleChangeName(event: any) {
    setApiName(event.target.value);
  }

  function handleEditName(event: any) {
    setReadOnlyName(false);
    setNameFocus();
  }

  function handleNameKeyDown(event: any) {

    if (event.keyCode === 13 && apiName) {
      setReadOnlyName(true);

      const dataApi = {
        mainUid: data.uid,
        market: "binance",
        name: apiName,
        updateUid: data.uid,
      };

      fetch('/api/key', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataApi),
      })
        .then((response) => {
          if (response.ok) {
            return fetchData();
          } else {
            return response.json().then((errorData) => {
              console.error('Server error:', errorData);
            });
          }
        })
        .catch((error) => {
          console.error('Network error:', error);
        });
    }
  }

  function showKey() {
    setDisplayKey(!displayKey)
  }

  return (
    <>
      <Stack direction="horizontal" gap={4} className='mx-3 align-items-start'>
        <Stack direction="vertical" gap={3}>
          <Row>
            <Col xs={3}>Nikname:</Col>
            <Col xs={9} className='account__grey text-white'>
              <input
                type="text"
                value={apiName}
                onChange={handleChangeName}
                onKeyDown={handleNameKeyDown}
                style={readOnlyName ? {
                  borderRadius: '30px',
                  textIndent: '15px',
                  padding: '5px 0',
                } : {borderRadius: '30px', textIndent: '15px', outline: '1px solid grey', padding: '5px 0'}}
                className={`bg-transparent account__input text-white border-0`}
                ref={nameRef}
                readOnly={readOnlyName}
                name='apiName'
              />
              <button type='button' className='bg-transparent'
                      onClick={handleEditName}>{getPencilIcon('0.8rem', '0.8rem')}</button>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>KEY:</Col>
            <Col xs={9} className='d-flex gap-4' style={{textIndent: '15px',}}>
              <input
                type={displayKey ? "text" : "password"}
                value={apiKey}
                style={{
                  borderRadius: '30px',
                  textIndent: '15px',
                  padding: '5px 0',
                }}
                className={`bg-transparent account__input text-white border-0 w-100`}
                readOnly
                name='apiKey'
              />
              <button type='button' className='bg-transparent' onClick={showKey}>
                {displayKey ? getEyeIcon() : getCrossEyeIcon()}</button>
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='text-secondary'>Market:</Col>
            <Col xs={9} className='account__grey' style={{textIndent: '15px'}}>Binance</Col>
          </Row>
          <Row>
            <Col xs={3} className='text-secondary'>Balance:</Col>
            <Col xs={9} style={{textIndent: '15px', color: '#36FF6C'}}>{data?.usdtBalance} USDT</Col>
          </Row>
        </Stack>
        {active && <div className="pt-1" style={{color: '#36FF6C'}}>Active</div>}
        {group.length > 0 && <div
            className="pt-1"
            onClick={() => onRepeatAccountsToggle(!openRepeatAccounts)}
            aria-controls='collapse-repeat-accounts'
            aria-expanded={openRepeatAccounts}
            style={{cursor: 'pointer'}}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 23 16"
                 fill="none">
                <path d="M21 2L12.7111 13.3427C12.1119 14.1627 10.8881 14.1627 10.2889 13.3427L2 2"
                      stroke="white" strokeWidth="3" strokeLinecap="round"/>
            </svg>
        </div>}
      </Stack>
      <hr className='account__hr'/>
    </>
  );
};