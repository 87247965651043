import { create } from 'zustand';
import { toast } from "react-toastify";
import { UserDataType } from "../../types/types";

interface UserState {
  userInfo: UserDataType | undefined;
  setUserInfo: (data: any) => void;
  fetchUserData: () => Promise<void>;
  updateUserData: (name: string, value: any) => Promise<void>;
}

const useUserStore = create<UserState>((set) => ({
  userInfo: undefined,
  fetchUserData: async () => {
    try {
      const response = await fetch('/api/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const data = await response.json();
        set({userInfo: data});
      } else {
        const errorData = await response.json();
        console.error('Server error:', errorData);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  },
  updateUserData: async (fieldName: string, value: any) => {
    try {
      let data;

      if (fieldName === 'password') {
        data = {
          password: value,
          passwordConfirmation: value,
        };
      } else {
        data = {
          [fieldName]: value,
        };
      }
      console.log(data)

      const response = await fetch('/api/user', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        const dataResponse = await response.json();

      } else {
        const errorData = await response.json();
        console.error('Server error:', errorData);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  },
  setUserInfo: (data) => set({userInfo: data}),
}));

export default useUserStore;
