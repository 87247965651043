import React, { FC, useEffect, useId, useState } from 'react';
import classNames from 'classnames';
import { Col, Collapse, ProgressBar, Row, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import bigDecimal from 'js-big-decimal';

import { capitalize, milisecondsToDate, showProgress } from '../../../utils/helpers';
import { MarketsTabsType } from '../../../types/enums';
import { MarketsTableInput } from '../MarketsTableInput/MarketsTableInput';
import { MarketsTableFourthSubRow } from "../MarketsTableSubRow/MarketsTableFourthSubRow";
import useNewOrderStore from "../../../services/hooks/useNewOrderStore";
import { getTrashIcon } from "../../../services/icons";

interface Props {
  id: number,
  data: any,
  counterEarning: boolean,
  tabType: MarketsTabsType,
  averageQuantity?: number,
  profitValue?: number,
  profitPercent: string,
  value: string,
  marketPrice: number,
  isFilled?: boolean,
  isRed?: boolean,
  show?: boolean
  groupData?: any,
}

export const MarketsTableRowFourthPosition: FC<Props> = ({
                                                           id,
                                                           data,
                                                           counterEarning,
                                                           tabType,
                                                           averageQuantity = 0,
                                                           profitValue = 0,
                                                           profitPercent = 0,
                                                           value,
                                                           isFilled = false,
                                                           isRed = false,
                                                           show,
                                                           marketPrice,
                                                           groupData,
                                                         }) => {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const collapseId = useId();

  const [groupResult, setGroupResult] = useState<{
    id: number,
    firstOrderId: number,
    value: number,
    profitValue: number,
    filledQuantity: number
  }[]>([]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  const isTpSl = data.secondTakeProfitFilledPrice > 0 || data.secondStopLossFilledPrice > 0 ? true : false;

  const dateFilledTime = milisecondsToDate(data.firstFilledTime);
  const dateTime = data.secondTakeProfitFilledTime !== 0 ? milisecondsToDate(data.secondTakeProfitFilledTime) : milisecondsToDate(data.secondStopLossFilledTime);
  const dataFilledPrice = bigDecimal.stripTrailingZero(bigDecimal.round(data.firstFilledPrice, data.counterRound));
  const dataPrice = isTpSl && data.secondTakeProfitFilledPrice ? bigDecimal.stripTrailingZero(bigDecimal.round(data.secondTakeProfitFilledPrice, data.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(data.secondStopLossFilledPrice, data.counterRound));
  const currentSymbol = counterEarning ? data.symbol.counterCurrency : data.symbol.baseCurrency;
  const currentSymbolValue = data.symbol.counterCurrency;
  const averageQuantityRemain = averageQuantity > 0 ? 100 - averageQuantity : 0;

  const removePosition = async () => {
    const dataRemove = {
      "market": data.symbol.market.toLowerCase(),
      "symbolName": data.symbol.name.toLowerCase(),
      "blockId": data.blockId
    };

    try {
      const id = toast.loading("Please wait...", {closeButton: true});
      const response = await fetch(`/api/markets/spot/fourth/close`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataRemove)
      });


      if (response.ok) {
        const {setRemoveOrder} = useNewOrderStore.getState();
        setRemoveOrder(data.blockId);
        toast.update(id, {
          render: 'Order successfully removed',
          type: 'success',
          autoClose: 5000,
          isLoading: false
        });
      } else {
        const errorData = await response.json();
        // Handle server-side validation errors or other server responses here
        console.error('Server error:', errorData);
        toast.update(id, {
          render: 'An error occurred while deleting the order',
          type: 'error',
          autoClose: 5000,
          isLoading: false
        });
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  }

  const handleResultsChange = (newResult: {
    id: number,
    firstOrderId: number,
    value: number,
    profitValue: number,
    filledQuantity: number
  }) => {

    setGroupResult(prevResults => {
      const existingResultIndex = prevResults.findIndex(r => r.firstOrderId === newResult.firstOrderId);

      // If the result already exists, update it, otherwise add a new result
      if (existingResultIndex > -1) {
        // Update the existing result
        const updatedResults = [...prevResults];
        updatedResults[existingResultIndex] = {...newResult};
        return updatedResults;
      } else {
        // Add new result to the array
        return [...prevResults, {...newResult}];
      }
    });
  };

  useEffect(() => {
    setGroupResult([]);
  }, [counterEarning]);

  const groupValue = groupResult.filter(result => result.id === id).reduce((sum, result) => sum + result.value, 0);
  const groupProfit = groupResult.filter(result => result.id === id).reduce((sum, result) => sum + result.profitValue, 0);
  const groupFilledQuantity = groupResult.filter(result => result.id === id).reduce((sum, result) => sum + result.filledQuantity, 0);


  const groupProfitRound = tabType === 'sell' ?
    counterEarning ?
      groupProfit > 0 ? data.symbol.counterRound : data.symbol.baseRound :
      groupProfit >= 1 ?
        data.symbol.baseRound :
        groupProfit > 0 && groupProfit < 1 ?
          data.symbol.counterRound :
          data.symbol.baseRound :
    //type=buy2
    counterEarning ?
      data.symbol.counterRound :
      groupProfit >= 1 ?
        data.symbol.baseRound :
        groupProfit > 0 && groupProfit < 1 ?
          data.symbol.counterRound :
          data.symbol.counterRound;

  const profit = groupData.length > 0 ?
    Number(new bigDecimal(groupProfit).round(groupProfitRound).getValue()) :
    profitValue;

  const percent = groupData.length > 0 && groupValue !== null ?
    tabType === MarketsTabsType.sell ?
      //sell
      counterEarning ?
        groupProfit > 0 ?
          Number(new bigDecimal(groupValue).getValue()) === 0 ?
            0 :
            Number(new bigDecimal(profit).divide(new bigDecimal(groupValue)).multiply(new bigDecimal(100)).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
          Number(new bigDecimal(groupFilledQuantity).getValue()) === 0 ?
            0 :
            Number(new bigDecimal(profit).divide(new bigDecimal(groupFilledQuantity)).multiply(new bigDecimal(100)).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
        groupProfit > 0 && groupProfit < 1 ?
          Number(new bigDecimal(groupValue).getValue()) === 0 ?
            0 :
            Number(new bigDecimal(profit).divide(new bigDecimal(groupValue)).multiply(new bigDecimal(100)).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
          Number(new bigDecimal(groupFilledQuantity).getValue()) === 0 ?
            0 :
            Number(new bigDecimal(profit).divide(new bigDecimal(groupFilledQuantity)).multiply(new bigDecimal(100)).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
      //buy
      counterEarning ?
        Number(new bigDecimal(groupValue).getValue()) === 0 ?
          0 :
          Number(new bigDecimal(profit).divide(new bigDecimal(groupValue)).multiply(new bigDecimal(100)).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :

        groupProfit > 1 ?
          Number(new bigDecimal(profit).divide(new bigDecimal(groupFilledQuantity)).multiply(new bigDecimal(100)).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
          Number(new bigDecimal(groupValue).getValue()) === 0 ?
            0 :
            Number(new bigDecimal(profit).divide(new bigDecimal(groupValue)).multiply(new bigDecimal(100)).round(2, bigDecimal.RoundingModes.DOWN).getValue()) :
    //without deal duplication
    Number(profitPercent);

  return (
    <div style={{border: isRed ? '1px solid red' : ''}} className={show ? "" : "d-none"}>
      <Row className='align-items-center justify-content-center fw-bold mt-2'>
        <Col
          onClick={groupData.length > 0 ? () => setOpen(!open) : undefined}
          aria-controls={groupData.length > 0 ? collapseId : ''}
          aria-expanded={groupData.length > 0 ? open : undefined}
          style={groupData.length > 0 ? {cursor: 'pointer'} : {cursor: 'default'}}
          className='ms-md-2 my-3 my-md-0'
          xs={3} md
        >
          <div className={classNames('markets-table__row-main', {open})}>
            <div className={classNames('markets-table__status', {
              'three': groupData.length > 0,
              'empty': groupData.length === 0,
            })}></div>
          </div>
        </Col>
        <Col md={9} className='mb-3 mb-md-0'>
          <Row className='markets-table__row py-1'>
            <Col xs={4} md className='my-1 my-md-0'>
              <p style={{color: '#8997dc'}}>{dateFilledTime}</p>
              <p>{dateTime}</p>
            </Col>
            <Col xs={4} md className='my-1 my-md-0'>
              <p style={{color: '#5b6aff'}}>{dataFilledPrice}</p>
              <p style={{color: '#ff363a'}}>{dataPrice}</p>
            </Col>
            <Col xs={4} md className='my-1 my-md-0'>{data.symbol.name}</Col>
            <Col xs={4} md
                 className='my-1 my-md-0'>{`${groupData.length > 0 ? Number(new bigDecimal(groupValue).round(4, bigDecimal.RoundingModes.DOWN).getValue()) : Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN).getValue())} ${currentSymbolValue}`}</Col>
            {tabType === 'buy'
              ? <Col
                xs={4} md
                className={`${profit > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profit > 0 ? "+" + profit : profit} 
                ${counterEarning ?
                data.symbol.counterCurrency :
                Number(new bigDecimal(data.secondStopLossFilledPrice).getValue()) === 0 ? data.symbol.counterCurrency :
                  Number(new bigDecimal(data.secondTakeProfitFilledQuantity ? data.secondTakeProfitFilledQuantity : data.secondStopLossFilledQuantity)
                    .multiply(new bigDecimal(data.secondTakeProfitFilledPrice ? data.secondTakeProfitFilledPrice : data.secondStopLossFilledPrice))
                    .round(4, bigDecimal.RoundingModes.DOWN)
                    .subtract(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN))
                    .getValue()) < Number(new bigDecimal(data.baseQuantityStep).multiply(new bigDecimal(marketPrice)).getValue()) ?
                    data.symbol.counterCurrency :
                    data.symbol.baseCurrency}`}
              </Col>
              : <Col
                xs={4} md
                className={`${profit > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profit}
                ${counterEarning ?
                profit >= 0 ? data.symbol.counterCurrency : data.symbol.baseCurrency :
                Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                  .subtract(new bigDecimal(data.secondTakeProfitFilledQuantity ? data.secondTakeProfitFilledQuantity : data.secondStopLossFilledQuantity)
                    .multiply(new bigDecimal(data.secondTakeProfitFilledPrice ? data.secondTakeProfitFilledPrice : data.secondStopLossFilledPrice)))
                  .round(4, bigDecimal.RoundingModes.DOWN)
                  .getValue()) >= 0 && Number(new bigDecimal(value).round(data.counterRound, bigDecimal.RoundingModes.DOWN)
                  .subtract(new bigDecimal(data.secondTakeProfitFilledQuantity ? data.secondTakeProfitFilledQuantity : data.secondStopLossFilledQuantity)
                    .multiply(new bigDecimal(data.secondTakeProfitFilledPrice ? data.secondTakeProfitFilledPrice : data.secondStopLossFilledPrice)))
                  .round(4, bigDecimal.RoundingModes.DOWN)
                  .getValue()) < Number(new bigDecimal(data.baseQuantityStep).multiply(new bigDecimal(data.secondTakeProfitFilledPrice ? data.secondTakeProfitFilledPrice : data.secondStopLossFilledPrice)).round(4, bigDecimal.RoundingModes.DOWN).getValue()) ? data.symbol.counterCurrency : data.symbol.baseCurrency}`}
              </Col>
            }
            {percent > 0
              ? <Col xs={4} md className='text-success my-1 my-md-0'>{`+${percent}%`}</Col>
              : <Col xs={4} md className='text-danger my-1 my-md-0'>{`${percent}%`}</Col>
            }
            <Col xs={4} md
                 className={`${tabType === 'buy' ? 'text-success my-1 my-md-0' : 'text-danger my-1 my-md-0'}`}>{capitalize(tabType)}</Col>
            <Col xs={4} md className='my-1 my-md-0'>
              <ProgressBar data-bs-theme='dark'>
                <ProgressBar variant="success" now={averageQuantity}
                             label={showProgress(averageQuantity)} key={1}/>
                <ProgressBar variant="danger" now={averageQuantityRemain} label={showProgress(averageQuantityRemain)}
                             key={2}/>
              </ProgressBar>
            </Col>
            <Col xs={4} md className='my-2 my-md-0'>
              <button type="button" className="" style={{backgroundColor: "transparent"}} onClick={removePosition}>
                {getTrashIcon("#ffffff", "13", "14")}
              </button>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          {isFilled ||
              <Stack direction="horizontal" className='markets-table__inputwrapper'>
                  <MarketsTableInput counterRound={data.counterRound} placeHolder={'S.L.'}
                                     inputValue={isTpSl ? '' : bigDecimal.round(data.secondStopLossPlacedPrice, 2)}/>
                  <span style={{color: '#7783c0'}}>/</span>
                  <MarketsTableInput counterRound={data.counterRound} placeHolder={'T.P.'}
                                     inputValue={isTpSl ? bigDecimal.round(data.secondTakeProfitPlacedPrice, 2) : ''}/>
                  <button className='markets-table__button' onClick={handleShowModal}>
                      More
                  </button>
              </Stack>
          }
        </Col>
      </Row>

      {groupData.length > 0 && groupData.map((subRow: any, index: number) => {
        return (
          <Collapse in={open} key={index}>
            <div id={collapseId}>
              <MarketsTableFourthSubRow
                key={index}
                data={subRow}
                tabType={subRow.section.toLowerCase()}
                currentSymbol={currentSymbol}
                counterEarning={counterEarning}
                marketPrice={marketPrice}
                onResultsChange={handleResultsChange}
              />
            </div>
          </Collapse>
        )
      })}
    </div>
  );
};